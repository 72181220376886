import React, { useState, useEffect, useRef } from 'react';
import Tree from 'react-d3-tree';
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import colors from '../../assets/colors';
import "../../styles/Home.css";
import "../../styles/Tree.css";

const Organi = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [viewMode, setViewMode] = useState('recrutement');
    const [zoomLevel, setZoomLevel] = useState(1); // État pour le niveau de zoom
    const [translate, setTranslate] = useState({ x: 0, y: 0 }); // Position initiale de l'arbre

    const containerRef = useRef(null); // Référence pour le conteneur

    // Fetch data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const token = localStorage.getItem('token');
                const response = await fetch(`${apiUrl}/api/allemploye/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                });
    

                const result = await response.json();

                if (response.ok) {
                    setData(result);
                    setLoading(false);
                } else {
                    setError(result);
                    setLoading(false);
                }
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Centrage automatique de l'arbre dans le conteneur au chargement
    useEffect(() => {
        if (containerRef.current) {
            const dimensions = containerRef.current.getBoundingClientRect();
            setTranslate({
                x: dimensions.width / 2, // Centrage horizontal
                y: dimensions.height / 4, // Centrage vertical
            });
        }
    }, [loading]);

    const toggleViewMode = () => {
        setViewMode(viewMode === 'recrutement' ? 'performance' : 'recrutement');
    };

    const transformData = (node) => {
        const nodeType = 'Employe';

        return {
            name: `${node.first_name} ${node.last_name}`.trim(),
            attributes: {
                poste: node.poste,
                type: nodeType,
                id: node.id,
                reports_to: node.reports_to?.username || null,
            },
            children: [] // You may need to transform subordinates if available
        };
    };

    // Fonction pour rendre les nœuds personnalisés
    const renderCustomNode = ({ nodeDatum }) => {
        const nodeType = nodeDatum.attributes.type;

        let fillColor = '#FFA500'; // Couleur par défaut pour la vue Recrutement (Orange)

        if (viewMode === 'recrutement') {
            if (nodeType === 'OpenPosition') {
                fillColor = '#8A2BE2'; // Violet pour les postes ouverts
            }
        } else if (viewMode === 'performance') {
            // Vue Performance
            fillColor = '#00aaff'; // Color logic for performance
        }

        return (
            <g>
                <circle r="15" fill={fillColor} />
                {zoomLevel > 0.6 && nodeDatum.name.trim() && (
                    <text fill="black" x="20" dy={-20}>
                        {nodeDatum.name}
                    </text>
                )}
                {zoomLevel > 0.6 && nodeDatum.attributes?.poste && (
                    <text fill="gray" x="20" dy={-5}>
                        {nodeDatum.attributes.poste}
                    </text>
                )}
            </g>
        );
    };

    if (loading) {
        return <div>Chargement de l'organigramme...</div>;
    }

    if (error) {
        return <div>Erreur : {error.message}</div>;
    }

    const treeData = data.map(transformData); // Transform fetched data for the tree

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home' ref={containerRef}>
            <Sidebar />
            <div className="container-right">
                <Header />

                <h4>{viewMode === 'recrutement' ? 'Vue Recrutement' : 'Vue Performance'}</h4>
                
                <button type="submit" onClick={toggleViewMode}>
                    Passer à la vue {viewMode === 'recrutement' ? 'Performance' : 'Recrutement'}
                </button>

                {/* Conteneur pour l'organigramme */}
                <Tree
                    data={treeData}
                    nodeSvgShape={{ shape: 'none' }}
                    leafNodeSvgShape={{ shape: 'none' }}
                    renderCustomNodeElement={renderCustomNode}
                    orientation="vertical"
                    zoom={zoomLevel}  // Utilisation du niveau de zoom défini dans l'état
                    scaleExtent={{ min: 0.1, max: 5 }} // Limites de zoom
                    onUpdate={({ zoom }) => setZoomLevel(zoom)} // Gestion des événements de zoom
                    translate={translate} // Centrage de l'arbre
                />

                <div style={{ marginTop: '20px' }}>
                    {viewMode === 'recrutement' ? (
                        <div>
                            <h4>Légende Recrutement</h4>
                            <ul>
                                <li><span style={{ backgroundColor: '#FFA500', padding: '2px 5px' }}>Orange</span> : Employé</li>
                                <li><span style={{ backgroundColor: '#8A2BE2', padding: '2px 5px' }}>Violet</span> : Poste Ouvert</li>
                            </ul>
                        </div>
                    ) : (
                        <div>
                            <h4>Légende Performance</h4>
                            <ul>
                                <li><span style={{ backgroundColor: '#D3D3D3', padding: '2px 5px' }}>Gris</span> : Haute Performance</li>
                                <li><span style={{ backgroundColor: '#00aaff', padding: '2px 5px' }}>Bleu</span> : Performance Neutre</li>
                                <li><span style={{ backgroundColor: '#ff6347', padding: '2px 5px' }}>Rouge</span> : Faible Performance</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Organi;
