import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "../../styles/Home.css";
import "../../styles/Card.css";
import { useParams } from 'react-router-dom';
import ButtonLinkEmployes from '../../components/buttons/ButtonLinkEmployes';
import HoverInfoCard from '../../components/cards/HoverInfoCard';

const ImportStaff = () => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const { reviewId } = useParams();
    
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setIsSuccessful(false); // Reset success state on file change
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            return;
        }

        setIsLoading(true);

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(`${apiUrl}/api/staffing_plan/upload/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setIsSuccessful(true);
        } catch (error) {
            console.error('There was an error uploading the file:', error);
            setIsSuccessful(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            
            const response = await fetch(`${apiUrl}/openposition/export_open_positions_to_csv/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'open_positions.csv';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('There was an error downloading the file:', error);
        }
    };

    return (
        <div className='cards-container'>
        <div className='card-half-full'>
        <div className='line-container-10'>
            <h5>Add your data  : </h5>
            <form className="form-column" onSubmit={handleFormSubmit}>
                <div className="form-group">
                    {!isLoading && !isSuccessful && (

                        <input 
                            id="fileInput"
                            type='file' 
                            accept='.csv' 
                            onChange={handleFileChange} 
                            disabled={isLoading}
                        />

                    )}
                    {isLoading ? (
                        <FontAwesomeIcon icon={faSpinner} className="spinner-icon" spin />
                    ) : isSuccessful ? (
                        <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
                    ) : null}
                </div>
                    <div className="display-line">
            {!isLoading && !isSuccessful && (
                    <button type='submit' disabled={isLoading}>Upload</button>
                )}
                            <button className='btn-secondary' onClick={handleDownload} disabled={isLoading}>
                Download
            </button>
            </div>
            </form>

        </div>
        </div>
       
        <div className='card-half-full'>
        <HoverInfoCard infoText="This action triggers the function that matches HRIS data with open positions based on the key future email.">

            <h5>Resync SIRH - polare </h5>
            <ButtonLinkEmployes/>
            </HoverInfoCard>
        </div>
        
        </div>
    );
};

export default ImportStaff;
