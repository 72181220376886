import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Home.css";
import "../../styles/Card.css";

import TurnoverRate from '../../components/kpi/TurnoverRate';
import EffectifNumber from '../../components/kpi/EffectifNumber';

import InsightTurnoverReasons from '../../components/insights/InsightTurnoverReasons';
import InsightTurnoverAtRisk from '../../components/insights/InsightTurnoverAtRisk';


const RetentionPage = () => {
    const [semestreData, setSemestreData] = useState([]);

    useEffect(() => {
        fetchSemestreData();
    }, []);

    const fetchSemestreData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiUrl}/api/turnover/semestre`, {
                headers: { 'Authorization': `Token ${token}` },
            });

            if (!response.ok) {
                throw new Error('Erreur de réseau ou réponse non valide');
            }

            const data = await response.json();
            setSemestreData(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des données:", error);
        }
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className='line-container-10'>


            </div>
            <div className='cards-container'>
                <TurnoverRate/>
                <EffectifNumber/>
            </div>
            <div className='line-container-10'>
                <h5>Analyses</h5>
                <div className='cards-container'>
                    <InsightTurnoverReasons/>
                    <InsightTurnoverAtRisk/>
                </div>
            </div>      
        </div> 
        </div>
    );
};

export default RetentionPage;

