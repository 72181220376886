import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Loader from '../assets/Loader';
import TurnoverRate from '../components/kpi/TurnoverRate';
import InsightTurnoverAtRisk from '../components/insights/InsightTurnoverAtRisk';
import "../styles/Text.css";
import "../styles/Container.css";
import "../styles/Home.css";
import ReportCard from '../components/cards/ReportCard';


import InsightAgeDistribution from '../components/insights/Diversity/InsightAgeDistribution';

import IndexHF from '../components/kpi/diversity/IndexHF';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('KPI'); // État pour suivre l'onglet actif
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 800);
  }, []);

  // Fonction pour changer l'onglet actif
  const selectTab = (tab) => {
    setActiveTab(tab);
  };
  const getLastThreeMonths = () => {
    const today = new Date();
    let months = [];
    for (let i = 1; i <= 3; i++) {
      let d = new Date(today.getFullYear(), today.getMonth() - i + 1, 1);
      months.push(`${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`);
    }
    return months;
  };
  // Contenu des onglets
  const renderTabContent = () => {
    switch (activeTab) {
      case 'KPI':
        return (
          <>
            <div className='line-container-10'>
                <h5>Overview</h5>
            </div>
            <div className='cards-container'>
                <TurnoverRate/>

                <IndexHF/>
            </div>
            <div className='line-container-10'>
                <h5>Analytics</h5>
                <div className='cards-container'>
                    <InsightTurnoverAtRisk/>
                    <InsightAgeDistribution/> 

                </div>
            </div>
          </>
        );
      case 'Reports':
          const lastThreeMonths = getLastThreeMonths();

          return( 
            <div className='line-container-10'>
            <h5>Reports</h5>
            <a  href="https://staging--polare.netlify.app/report_example.pdf" download>

              <button type='submit'>
                Télécharger un reporting
              </button>
            </a>
          
            <div className='cards-container'>
            {lastThreeMonths.map((date, index) => (
              <ReportCard key={index} date={date} />
            ))}
            </div>
          </div>
          );
      default:
        return null;
    }
  };
  return (
    <div className='container-home'>
      <Sidebar />
      {isLoading ? <div><Loader /></div> : (
        <div className="container-right">
          <Header />
          <div className='tab-menu'>
            <button
              className={`tab-menu-btn ${activeTab === 'KPI' ? 'tab-menu-btn-active' : ''}`}
              onClick={() => selectTab('KPI')}
            >
              KPI
            </button>
            <button
              className={`tab-menu-btn ${activeTab === 'Reports' ? 'tab-menu-btn-active' : ''}`}
              onClick={() => selectTab('Reports')}
            >
              Reports
            </button>
          </div>
          <div className="card-insight-separator"> </div>
          {renderTabContent()}
        </div>
      )}
    </div>
  );
}

export default Home;
