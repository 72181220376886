import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend } from 'chart.js';
import HomeCards from '../../cards/HomeCards';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';

ChartJS.register(Tooltip, Legend);

const TargetCompany = () => {
    const [chartData, setChartData] = useState(null);
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [forecast12months, setForecast12months] = useState('Chargement...');
    const navigate = useNavigate();
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    const handleOnClick = () => {
        amplitude.getInstance().logEvent('OverviewCardClicked', {
            username: ampData.username || 'unknown',
            graph: 'Compare Recruit',
            description: 'Difference between forecast and real on current month',
        });

        navigate('/staffingplan/insight/prevision');
    };

    const processChartData = (data) => {

        const lastEstimatedValue =data.slice(-1)[0].op_historique_reforecast;
        const lastRealValue = data.slice(-1)[0].op_historique_real;

        // Calculer le pourcentage de complétion
        const completionPercentage = (lastRealValue / lastEstimatedValue) * 100;
        setCompletionPercentage(completionPercentage);

        const remainingPercentage = 100 - completionPercentage;

        // Définir les données du graphique en secteurs (Pie chart)
        setChartData({
            labels: ['Completed', 'Remaining'],
            datasets: [
                {
                    label: 'Completion',
                    data: [completionPercentage, remainingPercentage],
                    backgroundColor: ['#EBA49B', '#EBA49B30'],
                    hoverBackgroundColor: ['#EBA49B', '#EBA49B5'],
                },
            ],
        });

        // Mettre à jour le KPI avec le pourcentage de complétion formaté
        setForecast12months(`${completionPercentage.toFixed(1)}%`);
    };

    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const storageKey = 'TargetCompany';
        const oneDay = 24 * 60 * 60 * 1000;

        const cachedData = localStorage.getItem(storageKey);

        const isCacheValid = (timestamp) => {
            return new Date() - new Date(timestamp) < oneDay;
        };

        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);

            if (isCacheValid(timestamp)) {
                processChartData(data);
                return;
            }
        }

        try {
            const currentYear = new Date().getFullYear();
            const startDate = new Date(`${currentYear}-01-01`);
            const endDate = new Date(`${currentYear}-12-31`);
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];

            const url = `${apiUrl}/openposition/compare_recrutement?start_date=${formattedStartDate}&end_date=${formattedEndDate}&period=month`;
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données d\'effectif');
            }

            const responseData = await response.json();
            const { data } = responseData;

            if (Array.isArray(data)) {
                localStorage.setItem(storageKey, JSON.stringify({ data, timestamp: new Date() }));
                processChartData(data);
            } else {
                throw new Error('Data fetched is not an array');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const chartOptions = {
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <HomeCards
            text="Target of the company on recruitment for the year"
            kpi={forecast12months}
            chart={chartData && <Pie data={chartData} options={chartOptions} height={65} width={1000} />}
            onClick={handleOnClick}
        />
    );
};

export default TargetCompany;
