import React, { useState, useEffect } from 'react';
import InsightCard from '../../cards/InsightCard'; // Assurez-vous du bon chemin d'importation
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';

const InsightPopulationEquipe = () => {
    const [data, setData] = useState([]);
    const [lastRefreshDate, setLastRefreshDate] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Date par défaut aujourd'hui
    const navigate = useNavigate();
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    const handleOnClick = () => {
        amplitude.getInstance().logEvent('OverviewCardClicked', {
            username: ampData.username || 'unknown',
            graph: 'Insight Staff Team Late',
            description: 'Teams behind their recruitment goal',
        });

        navigate('/staffingplan/insight/team');
    };

    const fetchData = async (date) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');

            const response = await fetch(`${apiUrl}/openposition/population_team_by_period/?date=${"2024-12-31"}`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données');
            }

            const data = await response.json();
            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };

            localStorage.setItem('InsightStaffLate', JSON.stringify(dataToStore));
            setData(data);
            setLastRefreshDate(now);
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    useEffect(() => {
        const storedData = localStorage.getItem('InsightStaffLate');
        if (storedData) {
            const { data, lastUpdated } = JSON.parse(storedData);
            setData(data);
            setLastRefreshDate(lastUpdated);
        } else {
            fetchData(selectedDate); // Fetch data with current date on load
        }
    }, [selectedDate]);

    // Fonction pour gérer la modification de la date
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
        fetchData(event.target.value); // Re-fetch data when the date changes
    };

    // Trier les données en fonction des postes ouverts signés (open_positions_signed)
    const sortedData = Array.isArray(data) 
    ? data.sort((a, b) => (b.open_positions_signed + b.population) - (a.open_positions_signed + a.population)) 
    : [];


    // Prendre les 3 premières équipes avec les plus grands nombres de postes signés
    const top3Teams = sortedData.slice(0, 3);

    const chartData = {
        labels: top3Teams.map(item => item.team),
        datasets: [
            {
                label: 'Historique emploi',
                data: top3Teams.map(item => item.population), // Représente le nombre de personnes présentes
                backgroundColor: 'rgba(54, 162, 235, 0.6)', // Bleu pour l'historique emploi
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
            },
            {
                label: 'Open positions signed',
                data: top3Teams.map(item => item.open_positions_signed), // Représente les postes ouverts signés
                backgroundColor: 'rgba(255, 99, 132, 0.6)', // Rouge pour les postes ouverts
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            }
        ]
    };

    const chartOptions = {
        indexAxis: 'y', // Passe le graphique en format horizontal
        scales: {
            x: {
                stacked: true, // Empiler les barres sur l'axe X
                display: false, // Cache les graduations et le fond de l'axe X
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe X
                }
            },
            y: {
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe Y
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false // Affiche la légende pour distinguer les deux types de données
            }
        },
        elements: {
            bar: {
                borderWidth: 0, // Cache les bordures des barres si nécessaire
            }
        }
    };

    const barChart = (
        <Bar data={chartData} options={chartOptions} height={110} width={300} />
    );

    const topTeam = top3Teams.length > 0 ? top3Teams[0].team : 'N/A';
    const updatedSubtext = `Most people: ${topTeam}`;

    return (
        <div>


            <InsightCard
                text="Workorce by team (end of the year)"
                subtext={updatedSubtext}
                data={barChart}
                onClick={handleOnClick}
            />
        </div>
    );
};

export default InsightPopulationEquipe;
