import React, { useState, useEffect } from 'react';
import "../../styles/Home.css";
import "../../styles/Card.css";
import ForecastEmployes from '../../components/kpi/Recrutement/ForecastEmployes';
import CompareRecruit from '../../components/kpi/Recrutement/CompareRecruit';


import TargetCompany from '../../components/kpi/Recrutement/TargetCompany';
import InsightPopulationEquipe from '../../components/insights/recrutement/InsightPopulationEquipe';
import InsightPagePopulationLocation from '../insights/recrutement/InsightPagePopulationLocation';
import InsightPopulationLocation from '../../components/insights/recrutement/InsightPopulationLocation';
import HoverInfoCard from '../../components/cards/HoverInfoCard';




const OverviewRecrutement = () => {


    return (

            <div className='line-container-10'>
                <h5>Overview</h5>
                
            <div className='cards-container'>


                <HoverInfoCard infoText="It's number of arrived / forecasted  ">
                    <TargetCompany/>
                </HoverInfoCard>
                <ForecastEmployes/>
                <HoverInfoCard infoText="The remaining number shows the projected hires by the end of the year, based on the forecasted arrival dates.">
                    <CompareRecruit/>    
                </HoverInfoCard>
              

            </div>
            <div className='line-container-10'>
                <h5>Analytics</h5>
                <div className='cards-container'>
                    

                    <InsightPopulationEquipe/>

                    <InsightPopulationLocation/>
                    
                </div>
            </div>      
            </div>

    );
};

export default OverviewRecrutement;

