import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import '../styles/Sidebar.css';

import logo_txt from "../assets/logo_txt.jpg";
import logo_img from "../assets/logo.png";
import colors from '../assets/colors.js';
import ButtonSidebar from './buttons/ButtonSidebar';
import { faHouse, faHeart, faElevator, faPeopleGroup, faChartArea, faClipboardList, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import ButtonLogout from './buttons/ButtonLogout';
import ButtonReset from './buttons/ButtonReset';
import ButtonLinkEmployes from './buttons/ButtonLinkEmployes.js';

const Sidebar = ({ initiallyCollapsed = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);
  
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const userData = JSON.parse(localStorage.getItem('userData')) || {};
  const { groupes, autorisation_features } = userData;
  const groupeDRH = Array.isArray(groupes) && groupes.includes('DRH');
  const features = Array.isArray(autorisation_features) ? autorisation_features : [];

  return (
    <div style={{ backgroundColor: colors.background, width: isCollapsed ? '80px' : '250px' }} className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className='sidebar-top'>
        <img src={logo_txt} className='logo' alt="Logo" style={{ display: isCollapsed ? 'none' : 'block' }}></img>
        <img src={logo_img} className='logo_img' alt="Logo" style={{ display: !isCollapsed ? 'none' : 'block' }}></img>
      
        <ButtonSidebar text="Home" icon={faHouse} redirectTo="/" isCollapsed={isCollapsed} />
        {features.includes('Rétention') && (
          <ButtonSidebar text="Retention" icon={faHeart} redirectTo="/retention" isCollapsed={isCollapsed} />
        )}
        {features.includes('Effectif') && (
          <ButtonSidebar text="Workforce" icon={faPeopleGroup} redirectTo="/effectif" isCollapsed={isCollapsed} />
        )}
        {features.includes('Diversité') && (
          <ButtonSidebar text="Diversity" icon={faElevator} redirectTo="/diversite" isCollapsed={isCollapsed} />
        )}
        {features.includes('Staffing Plan') && (
          <ButtonSidebar text="Staffing Plan" icon={faClipboardList} redirectTo="/staffingplan" isCollapsed={isCollapsed} />
        )}
        {features.includes('Performance') && (
          <ButtonSidebar text="Performance" icon={faChartArea} redirectTo="/performance" isCollapsed={isCollapsed} />
        )}
      </div>
      <div className='sidebar-bottom'>
        <ButtonLogout />
        <ButtonReset /> 

      </div>
    </div>
  );
};

export default Sidebar;
