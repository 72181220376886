import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

const GraphStaffCompare = () => {
    const currentYear = new Date().getFullYear();
    const endOfYear = new Date(`${currentYear}-12-31`);
    const lastYear = new Date(`${currentYear}-01-01`);


    const [chartData, setChartData] = useState(null);
    const [groupOption, setGroupOption] = useState('month');
    const [startDate, setStartDate] = useState(formatDate(lastYear));
    const [endDate, setEndDate] = useState(formatDate(endOfYear));

    

    const [filters, setFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState({ column: '', value: '', displayValue: '' });
    const [filterOptions, setFilterOptions] = useState({ teams: [], locations: [] });

    const processChartData = (data) => {
        const labels = data.map(item => item.periode);
        const estimatedData = data.map(item => item.op_historique_reforecast);
        const ophistorique = data.map(item => item.op_historique_real);


        setChartData({
            labels,
            datasets: [
                {
                    label: 'Current workforce',
                    data: ophistorique,
                    borderColor: '#EBA49B',
                    backgroundColor: '#EBA49B40',
                    fill: true,
                    tension: 0.1
                },
                
                {
                    label: 'Forecast workforce',
                    data: estimatedData,
                    borderColor: '#93c2db',
                    backgroundColor: '#93c2db40',
                    fill: true,
                    tension: 0.1
                },
            ],
        });
    };

    const fetchFilterOptions = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const url = `${apiUrl}/openposition/compare_recrutement/`;
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching filter options');
            }

            const data = await response.json();
            setFilterOptions(data.filters);
        } catch (error) {
            console.error('Error fetching filter options', error);
        }
    };

    const fetchData = async () => {
        const storageKey = `GraphStaffCompare_${startDate}_${endDate}_${groupOption}_${JSON.stringify(filters)}`;
        const cachedData = localStorage.getItem(storageKey);
        const oneDay = 24 * 60 * 60 * 1000;
        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (new Date() - new Date(timestamp) < oneDay) {
                processChartData(data);
                return;
            }
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const filterParams = filters.map(filter => `${filter.column}=${filter.value}`).join('&');
        const url = `${apiUrl}/openposition/compare_recrutement/?start_date=${startDate}&end_date=${endDate}&period=${groupOption}&${filterParams}`;
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching workforce data');
            }

            const { data } = await response.json();
            localStorage.setItem(storageKey, JSON.stringify({ data, timestamp: new Date() }));
            processChartData(data);
        } catch (error) {
            console.error('Error fetching workforce data', error);
        }
    };

    useEffect(() => {
        fetchFilterOptions();
        fetchData();
    }, [startDate, endDate, groupOption, filters]);

    const handleGroupChange = (event) => {
        setGroupOption(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value) {
            const displayValue = currentFilter.column === 'team'
                ? filterOptions.teams.find(team => team.id === parseInt(currentFilter.value, 10))?.name
                : currentFilter.value;
            setFilters([...filters, { ...currentFilter, displayValue }]);
            setCurrentFilter({ column: '', value: '', displayValue: '' });
        }
    };

    const handleRemoveFilter = (index) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setCurrentFilter({ ...currentFilter, [name]: value });
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className="card-full">
                    <h5>Filter by</h5>
                    <div className="display-line dropdown">
                        <select className="dropdown" name="column" onChange={handleFilterChange} value={currentFilter.column}>
                            <option value="">Select Column</option>
                            <option value="location">Location</option>
                            <option value="team">Team</option>
                        </select>
                        <select className="dropdown" name="value" onChange={handleFilterChange} value={currentFilter.value} disabled={!currentFilter.column}>
                            <option value="">Select Value</option>
                            {currentFilter.column === 'location' && filterOptions.locations.map((location, index) => (
                                <option key={index} value={location}>{location}</option>
                            ))}
                            {currentFilter.column === 'team' && filterOptions.teams.map((team, index) => (
                                <option key={index} value={team.id}>{team.name}</option>
                            ))}
                        </select>
                        <button type="button" onClick={handleAddFilter} className="btn-secondary" disabled={!currentFilter.column || !currentFilter.value}>Add Filter</button>
                    </div>
                    <div className="display-line">
                        {filters.map((filter, index) => (
                            <div key={index} className="filter-tag">
                                {filter.column}: {filter.displayValue}
                                <button className="filter-tag-btn" type="button" onClick={() => handleRemoveFilter(index)}>x</button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='card-full'>
                    <div className="display-line">
                        <div className="display-line">
                            Start Date
                            <input type="date" className="form-input" value={startDate} onChange={handleStartDateChange} />
                        </div>
                        <div className="display-line">
                            End Date
                            <input type="date" className="form-input" value={endDate} onChange={handleEndDateChange} />
                        </div>
                        <div className="display-line">
                            Group By
                            <select value={groupOption} onChange={handleGroupChange}>
                                <option value="month">Month</option>
                                <option value="quarter">Quarter</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className='card-full margin-b-40'>
                    
                {chartData && <Line data={chartData}
    options={{
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Period'
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Total Recruits'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                display: true,
                text: "Actual and forecasted workforce over the year",
                font: {
                    size: 16
                },
                padding: {
                    top: 10,
                    bottom: 30
                },
            },
            
        },
    }}
/>}

                 
                </div>
            </div>
        </div>
    );
};

export default GraphStaffCompare;
