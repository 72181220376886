import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";

const InsightPagePopulationLocation = () => {
    const [data, setData] = useState([]);
    const [lastRefreshDate, setLastRefreshDate] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Date par défaut aujourd'hui
    const navigate = useNavigate();
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    const fetchData = async (date) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');

            const response = await fetch(`${apiUrl}/openposition/population_location_by_period/?date=${date}`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données');
            }

            const data = await response.json();
            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };

            localStorage.setItem('InsightPopulationLocation', JSON.stringify(dataToStore));
            setData(data);
            setLastRefreshDate(now);
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    useEffect(() => {
        const storedData = localStorage.getItem('InsightPopulationLocation');
        if (storedData) {
            const { data, lastUpdated } = JSON.parse(storedData);
            setData(data);
            setLastRefreshDate(lastUpdated);
        } else {
            fetchData(selectedDate); // Fetch data with current date on load
        }
    }, [selectedDate]);

    // Fonction pour gérer la modification de la date
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
        fetchData(event.target.value); // Re-fetch data when the date changes
    };

    // Trier les données en fonction des postes ouverts signés (open_positions_signed)
    const sortedData = Array.isArray(data) ? data.sort((a, b) => b.open_positions_signed - a.open_positions_signed) : [];

    const chartData = sortedData.length > 0 ? {
        labels: sortedData.map(item => item.location),
        datasets: [
            {
                label: 'Current Staff',
                data: sortedData.map(item => item.population), // Représente le nombre de personnes présentes
                backgroundColor: 'rgba(54, 162, 235, 0.6)', // Bleu pour l'historique emploi
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
            },
            {
                label: 'Open Positions Signed',
                data: sortedData.map(item => item.open_positions_signed), // Représente les postes ouverts signés
                backgroundColor: 'rgba(255, 99, 132, 0.6)', // Rouge pour les postes ouverts
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            }
        ]
    } : null;

    const chartOptions = {
        maintainAspectRatio: false, // Désactive le ratio d'aspect pour plus de flexibilité dans la taille
        scales: {
            x: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Location', // Axe des équipes
                }
            },
            y: {
                beginAtZero: true, // Commence l'axe Y à zéro
                stacked: true,
                title: {
                    display: true,
                    text: 'Staff Count & Signed Positions' // Titre de l'axe Y
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom', // Légende en bas du graphique
            },
            title: {
                display: true,
                text: "Current Staff and open positions forcasted", // Titre du graphique
                font: {
                    size: 16 // Taille du texte du titre
                },
                padding: {
                    top: 10,
                    bottom: 30 // Espacement autour du titre
                },
            },
        },
        responsive: true, // Adaptation à la taille de l'écran
        elements: {
            bar: {
                borderWidth: 1, // Bordures des barres
            }
        }
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className='card-full'>

                        <label htmlFor="date-select">Select date for forecast:</label>
                        <div className='dropdown'>
                            <input 
                                type="date" 
                                id="date-select" 
                                value={selectedDate} 
                                onChange={handleDateChange} 
                                style={{ marginLeft: '10px' }} 
                            />      
                        </div>
                       

                </div>
                <div className='card-full margin-b-40'>

                    {chartData ? <Bar data={chartData} options={chartOptions} height={110} width={300} /> : <p>No data available</p>}
                </div>
            </div>
        </div>
    );
};

export default InsightPagePopulationLocation;
